.title {
	position: absolute;
	top: 50%;
	left: 12px;
	transform: translateY(-50%);
	padding-right: 8px;
	padding-left: 8px;
	background-color: rgb(229, 243, 244);
	color: grey;
}

.line-with-title-container {
	position: relative;
}

.series-row .nav-link {
	color: #fff;
	background-color: #434a52c1;
	/* Dark background for non-active pills */
}

.series-row .nav-link.active-pill {
	color: white;
	background-color: #ea5b0d !important; /* Light background for active pill */
}

.series-row .nav-link:hover {
	color: white;
	background-color: #ea5b0d !important; /* Optional: Hover effect for non-active pills */
}

.series-nav {
	display: flex;
	flex-direction: row !important;
}

@media (max-width: 768px) {
	.series-nav {
		flex-direction: column !important;
	}
}

.transition-enter {
	max-height: 0;
	opacity: 0;
	overflow: hidden;
	transition: max-height 0.5s ease-out, opacity 0.5s ease-out;
}

.transition-enter-active {
	max-height: 200px; /* Adjust based on your content's expected height */
	opacity: 1;
}

.transition-exit {
	max-height: 200px;
	opacity: 1;
	overflow: hidden;
	transition: max-height 0.5s ease-in, opacity 0.5s ease-in;
}

.transition-exit-active {
	max-height: 0;
	opacity: 0;
}
