.background-image {
	background-image: url('../../assets/Finn_Shred.jpg');
	background-size: cover;
	background-position: center;
	height: 100vh;
	/* Adjust the height as needed */
}

.dark-mask {
	background: linear-gradient(to bottom, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0.2) 50%, rgba(0, 0, 0, 0.8) 70%);
	/* Dark overlay with 60% opacity */
	height: 100%;
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 1;
	/* Ensure the overlay is above the background image */
}