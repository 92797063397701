.nav.nav-pills .nav-link.active {
	background-color: #212529;
}

.nav.nav-pills .nav-link.active .active-link {
	background-color: red !important;
}
.nav.nav-pills .nav-link {
	color: white;
}
