//UCI
$primary: #ea5b0d;

//RAMPAGE
$warning: #bd6425;

$success: #16686e;

.btn-primary {
	--bs-btn-color: white !important; // Change this to your desired font color
}

@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap');

.btn {
	--bs-btn-font-family: 'Roboto Condensed', sans-serif !important;
}

body {
	--bs-body-font-family: 'Roboto Condensed', sans-serif !important;
}

@import '../node_modules/bootstrap/scss/bootstrap.scss';
